import "bootstrap";
import "datatables.net";
import "datatables.net-bs4";

import bsCustomFileInput from "bs-custom-file-input";
import * as $ from "jquery";
import * as PhotoSwipe from "photoswipe";
import * as PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

import "./styles/app.scss";

// We use this regexp to strip 'tussenvoegsels' from last names so names can be
// sorted nicely. Generated based on https://nl.wikipedia.org/wiki/Tussenvoegsel
// using Emacs' `regexp-opt`.
const TUSSENVOEGSEL_REGEXP = /^(?:'[st]|a(?:an(?: (?:'t|de[nr]?|het))?|m de|u(?:f (?:de[mnr]|ter)|s (?:'m|de[mnr])|[fs])|[flm])|b(?:en|i(?:j (?:'t|den?|het)|[jn])|oven d')|d(?:a(?:lla|[,ls])|e(?: (?:die(?: le)?|l(?:as|['ae])|van der,)|ca|gli|lla|[ilnrs])|ie(?: le)?|o[ns]|['eiou])|el|het|i(?:n (?:'t|de[nr]?|het)|[mn])|l(?:[aeo]s|[aeo])|o(?:nder(?: (?:'t|den?|het))?|p (?:'t|de[nr]?|gen|het|ten)|ver(?: (?:'t|den?|het))?|['p])|t(?:e[nr]|ho[er]?|o[et]|[eo])|u(?:i(?:jt(?: (?:'t|den?|het|te(?: de|n)))?|t(?: (?:'t|den?|het|te(?: de|n)))?)|nter)|v(?:an(?: (?:'t|de(?: l'|[nr])?|gen|het|la|ter|van de)|den)?|er|o(?:n (?:'t|de[mnr])|or(?: (?:den?|in 't))?|r der|[mnr]))|zu[mr]?|[Lai]) +/i;

$(document).ready(function() {
  bsCustomFileInput.init();

  // Right now these are only used on the report pages.
  // `ReportImage.photoswipe_data` generates the slide data used by PhotoSwipe.
  const photos = $("[data-lightbox]");
  if (photos.length > 0) {
    const gallery = photos
      .map((_, photo) => JSON.parse(photo.dataset.lightbox!))
      .get();
    const pswpElement = $(".pswp")[0];

    photos.click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      const index = photos.index(event.currentTarget);
      const photoswipe = new PhotoSwipe(
        pswpElement,
        PhotoSwipeUI_Default,
        gallery,
        {
          index: index,
          getThumbBoundsFn: function(index) {
            const element = $(photos[index]);
            const offset = (element.is("img")
              ? element
              : element.find("img")
            ).offset()!;

            return { x: offset.left, y: offset.top, w: element.width()! };
          },
          showHideOpacity: true,
          bgOpacity: 0.95
        }
      );

      photoswipe.init();
    });
  }

  // Filterable and searchable user lists
  // Used on both the member listing and on the event pages
  $("#user-list").DataTable({
    paging: false,
    info: false,
    order: [1, "asc"],
    columnDefs: [
      {
        targets: "last-name",
        render: {
          // Strip tussenvoegsels so the last names can be sorted better
          sort: lastName => lastName.replace(TUSSENVOEGSEL_REGEXP, "")
        }
      }
    ],
    language: {
      processing: "Bezig...",
      lengthMenu: "_MENU_ resultaten weergeven",
      zeroRecords: "Geen resultaten gevonden",
      info: "_START_ tot _END_ van _TOTAL_ resultaten",
      infoEmpty: "Geen resultaten om weer te geven",
      infoFiltered: " (gefilterd uit _MAX_ resultaten)",
      infoPostFix: "",
      search: "Zoeken:",
      emptyTable: "Geen resultaten aanwezig in de tabel",
      loadingRecords: "Een moment geduld aub - bezig met laden...",
      paginate: {
        first: "Eerste",
        last: "Laatste",
        next: "Volgende",
        previous: "Vorige"
      },
      aria: {
        sortAscending: ": activeer om kolom oplopend te sorteren",
        sortDescending: ": activeer om kolom aflopend te sorteren"
      }
    }
  });
});
